import React, { Component } from "react";
import { css } from "@emotion/core";
// Search component
class Search extends Component {
  state = {
    query: "",
    results: []
  };

  render() {
    const ResultList = () => {
      if (this.state.results.length > 0) {
        return this.state.results.map((page, i) => (
          <div className="item-search" key={i}>
            <a
              href={`https://cdn.sanity.io/files/hu7mtxpb/production/${page.url.substring(
                5,
                45
              )}.pdf`}
              target="_blank"
              rel="noreferrer noopener"
              className="link"
            >
              {page.title}
            </a>
          </div>
        ));
      } else if (this.state.query.length > 2) {
        return "No results for " + this.state.query;
      } else if (
        this.state.results.length === 0 &&
        this.state.query.length > 0
      ) {
        return "Please insert at least 3 characters";
      } else {
        return "";
      }
    };

    return (
      <div
        className={this.props.classNames}
        css={css`
          display: flex;
          align-items: center;
          position: relative;
          z-index: 10;
          width: 300px;
          margin: 32px auto;
          label {
            /* Screenreader */
            clip: rect(1px, 1px, 1px, 1px);
            clip-path: inset(50%);
            height: 1px;
            width: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
          }
          input {
            width: 100%;
          }
          .search__list {
            position: absolute;
            top: 100%;
            left: 0;
          }
          .item-search {
            padding: 0.5rem 1rem;
            background: #ffffff;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px;
          }
        `}
      >
        <label htmlFor="search">Search</label>
        <input
          className="search__input"
          type="text"
          id="search"
          onChange={this.search}
          placeholder={"Batch #"}
        />

        <div className="search__list">
          <ResultList />
        </div>
      </div>
    );
  }

  getSearchResults(query) {
    var index = window.__FLEXSEARCH__.en.index;
    var store = window.__FLEXSEARCH__.en.store;
    if (!query || !index) {
      return [];
    } else {
      var results = [];
      Object.keys(index).forEach(idx => {
        results.push(...index[idx].values.search(query));
      });

      results = Array.from(new Set(results));

      var nodes = store
        .filter(node => (results.includes(node.id) ? node : null))
        .map(node => node.node);

      return nodes;
    }
  }

  search = event => {
    const query = event.target.value;
    if (this.state.query.length > 2) {
      const results = this.getSearchResults(query);

      this.setState({ results: results, query: query });
    } else {
      this.setState({ results: [], query: query });
    }
  };
}

export default Search;
