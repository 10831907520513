import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import Search from "../components/search";

const Index = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: sanitySiteSettings {
        heading
        paragraph
        order {
          title
          products {
            title
            slug {
              current
            }
            mainImage {
              asset {
                fixed(width: 300) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <section>
        <h1>{data.heading}</h1>
        <p>{data.paragraph}</p>
        <Search />
      </section>
      {data.order.map(collection => (
        <section key={collection.title}>
          <h2>{collection.title}</h2>

          <div className="products-container">
            {collection.products.map(product => (
              <div key={product.title}>
                <Link to={`/${product.slug.current}/`}>
                  <Img
                    fixed={product.mainImage.asset.fixed}
                    alt={product.mainImage.alt}
                    loading="lazy"
                  />
                  <p>{product.title}</p>
                </Link>
              </div>
            ))}
          </div>
        </section>
      ))}
    </Layout>
  );
};

export default Index;
